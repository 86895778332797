/**
 * <PRE>
 * System Name   : 사랑온 NEXT 시스템(TSS2-MSP)
 * Business Name : 청약
 * Class Name    : PSAmlUtil.js
 * Description   : AML 대응 관련  Util
 * </PRE>
 */
 import PSCommUtil from '~/src/ui/ps/comm/PSCommUtil'
 import PSConstants from '@/config/constants/psConstants'
 import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 

 const PSAmlUtil = {}

/******************************************************************************
 * Function명  : PSAmlUtil.IsAmlPrfmc
 * 설명        : AML 대상 여부 리턴
 *               amlRltnTypCd : 1 계약자 , 2 대리인(친권자), 3 사망수익자  
 *               jsonInputData : 가설에서 발행시 화면으로 넘어온 파라미터 
 *                               속에 jsonInputData 포함되어 있음
 *                              => AML 서식 존재 여부 판별 기준 데이터
 *               isCorp : 법인단체 여부
 ******************************************************************************/


PSAmlUtil.fn_IsAmlPrfmc = function(amlRltnTypCd,jsonInputData,isCorp=false){  
  let formList = null
  let trctFormId = ''
  let isAmlPrfmc = false
  let i = 0 
  //모바일 청약 대상DATA 접근 CASE
  formList = (jsonInputData && jsonInputData.zaTrnsRsltCntnt && jsonInputData.zaTrnsRsltCntnt.META_ARY)? jsonInputData.zaTrnsRsltCntnt.META_ARY : null

  if(! formList){
    //전자서명 대상DATA 접근 CASE 
    formList = (jsonInputData && jsonInputData.META_ARY)? jsonInputData.META_ARY : null
  }
  
  formList = formList? formList : []

  if('1' == String(amlRltnTypCd) && isCorp == false){ // 계약자 pvm_custkycctr
    trctFormId = PSConstants.FORM_NM_AML_CONTR
  }else if('1' == String(amlRltnTypCd) && isCorp){ // 법인단체 계약자 pvm_entkyc
      trctFormId = PSConstants.FORM_NM_CORP_AML_CONTR      
  }else if('2' == String(amlRltnTypCd)){ // 대리인(친권자01) pvm_custkycprx
    trctFormId = PSConstants.FORM_NM_AML_PRTR01
  }else if('3' == String(amlRltnTypCd)){// 사망수익자01 pvm_custkycben
    trctFormId = PSConstants.FORM_NM_AML_DTH_BNFR01
  }

  if(! (jsonInputData && formList && trctFormId)) return isAmlPrfmc

  for(i = 0 ; i < formList.length ; ++i){
    let formNm = formList[i].formname
    if(trctFormId == formNm){
      isAmlPrfmc = true //AML 서식 작성대상
      break
    }
  }//for 
  
  return isAmlPrfmc
 }

 /******************************************************************************
 * Function명  : PSAmlUtil.fn_GetBnfrInfo
 * 설명        : 심사결과 조회 결과 데이터에서 수익자 정보 리턴
 *               채널ID 조회 agtFileId에 셋팅 해서 리턴 
 ******************************************************************************/
 /*{
    "keyName": "보험대상자", "contvInsrdNm": "주피", "contvInsrdCd": "21", "custNm": "고객명",
    "age": 51, "custRrnId": "주민번호","cusDtlTypCd": "11","partnerId": "BPID값", "agtFileId": "채널고객ID", "shareVl": 100,
    "chldSnoVl": "", "bnfrRolNM": "사망수익자", "bnfrRolCd": "35","bnfrSno": 1
 }*/
  PSAmlUtil.fn_GetBnfrInfo = async function(context,bnfrInfoList,bnfrRolCd="35",bnfrSno="1"){ //기본인자값 사망수익자 이면서 첫번째
    let rtnVal = null
    let length = (bnfrInfoList)? bnfrInfoList.length : 0
    for(let i = 0 ; i < length ; ++i){
      let item = bnfrInfoList[i]
      if(item.bnfrRolCd == bnfrRolCd && String(item.bnfrSno) == bnfrSno){ 
        rtnVal = item
        break
      }
    }//for i
    
    if(! rtnVal) return rtnVal
    
    rtnVal.custRrnId = (rtnVal.custRrnId)? String(rtnVal.custRrnId).trim() : ''
    let custKnb = (rtnVal.custRrnId)? rtnVal.custRrnId : ''

    if(! custKnb) return rtnVal
    //채널ID 구하기
    let rsltCustInfo = await PSAmlUtil.fn_GetChnlId(context,custKnb)
    if(rsltCustInfo) rtnVal.agtFileId = (rsltCustInfo.chnlCustId)? rsltCustInfo.chnlCustId : ""
    console.log("▶▶PSAmlUtil.fn_GetBnfrInfo rsltCustInfo!!!!") 
    console.log(rtnVal)
    return rtnVal
  }

 
/******************************************************************************
 * Function명  : PSAmlUtil.fn_GetChnlId
 * 설명        : 고객 주민번호로 채널ID 조회(FC 본인모집고객 대상)
 *               knb : 평문 or 암호화값으로 조회가능
 ******************************************************************************/
 PSAmlUtil.fn_GetChnlId = async function(context,custKnb){
  let rtnData = null
  let srnId = (context && context.$options && context.$options.screenId)? context.$options.screenId : 'MSPPS420M' 
  let pParams = {}  
  pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99S12'))
  pParams.srnId    = srnId 
  pParams.data.knb = custKnb
  
  let rsltData  = await PSServiceManager.invokeSendAsync(pParams)  
  if(rsltData && rsltData.isError == false){
    rtnData = rsltData.data
  } 

  return rtnData
 }


 /******************************************************************************
 * Function명  : PSAmlUtil.fn_GetAmlChnlCustInfo
 * 설명        : AML 대상 고객 기본정보 조회
 *               context :호출 화면
 *               amlChnlCustId : 조회대상 채널 고객ID
 *               amlChnlCustNm : 조회대상 채널 고객명
 ******************************************************************************/
  PSAmlUtil.fn_GetAmlChnlCustInfo = async function(context,amlChnlCustId,amlChnlCustNm){
    let rtnData = null
    let srnId = (context && context.$options && context.$options.screenId)? context.$options.screenId : 'MSPPS610M'
    let pParams = {}  
    let cnsltNo = PSServiceManager.getUserInfo('userEno')
    
    pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S4'))
    pParams.srnId = srnId 
    pParams.data = {}    
    pParams.data.cnsltNo = cnsltNo
    pParams.data.chnlCustId = amlChnlCustId
    pParams.data.custNm = amlChnlCustNm

    console.log('PSAmlUtil.fn_GetAmlChnlCustInfo ')
    console.log(pParams)
    let rsltData  = await PSServiceManager.invokeSendAsync(pParams)
    console.log(rsltData)  

    /*
    if(rsltData && rsltData.isError == false){
      rtnData = rsltData.data
    }else{
    } 
    */
    return rsltData
  }  
 
  
  /************************************************************************************************
   * Function명  : async fn_UpdPrtrDthBnfrCustInfo 
   * 설명        : 발행전 전산심사에서 체크 단계 반영으로 사용 안함 스킵처리 
   *              Promise 패턴
                  친권자 사망수익자 AML 수행시 정보 불일치 방지 사망수익자 친권자 기준정보 업데이트 처리
                  친권자 사망수익자 사전입력및 전자서명 진행시 수신동의여부 질의 과정이 없어
                  친권자 사망수익자 전용 업데이트 서비스 호출
   ************************************************************************************************/
    
  PSAmlUtil.fn_UpdPrtrDthBnfrCustInfo = async function(scnAcpnPsbData){
    let contrPartnNo        = (scnAcpnPsbData.amlContrPartnNo)? String(scnAcpnPsbData.amlContrPartnNo).trim()             : '' //계약자 BPID
    let insrdPartnNo        = (scnAcpnPsbData.elstInsrdPartnNo)? String(scnAcpnPsbData.elstInsrdPartnNo).trim()            : '' //주피 BPID
    let amlDthBnfrPartnNo01 = (scnAcpnPsbData.amlDthBnfrPartnNo)? String(scnAcpnPsbData.amlDthBnfrPartnNo).trim()          : '' //사망수익자01 BPID
    let amlPrtrPesonPartnNo01    = (scnAcpnPsbData.amlPrtrPesonPartnNo)? String(scnAcpnPsbData.amlPrtrPesonPartnNo).trim() : '' //친권자01 BPID

    let amlDthBnfrChnlCustId01 = (scnAcpnPsbData.amlDthBnfrChnlCustId)? String(scnAcpnPsbData.amlDthBnfrChnlCustId).trim() : '' // 사망수익자01 채널고객ID
    let prtrChnlCustId01       = (scnAcpnPsbData.amlPrtrChnlCustId01)? String(scnAcpnPsbData.amlPrtrChnlCustId01).trim()   : '' //친권자01 채널고객ID

    let amlDthBnfrCustNm01  = (scnAcpnPsbData.amlDthBnfrCustNm)? String(scnAcpnPsbData.amlDthBnfrCustNm).trim() : '' //사망수익자01 고객명   
    let prtrNm01            = (scnAcpnPsbData.amlPrtrNm01)? String(scnAcpnPsbData.amlPrtrNm01).trim() : ''            //친권자01 고객명
    let rsltUpdDthBnfr = null , rsltUpdPrtr = null
    let rtnData = {isError : false , msgCntnt : ''}
    let screenId = 'MSPPS610M' // 대표 화면 명

    //사망수익자01 기준정보 업데이트 수행
    if(amlDthBnfrPartnNo01 &&           
        amlDthBnfrPartnNo01 != contrPartnNo &&
        amlDthBnfrPartnNo01 != insrdPartnNo ){ // 사망수익자01 존재  계수/피수/상이일때 업데이트

      let pParamsDthBnfr = {}
      pParamsDthBnfr = Object.assign({}, PSServiceManager.getParameter('txTSSPS13U4')) 
      pParamsDthBnfr.srnId = screenId // 대표 화면 명
      pParamsDthBnfr.data = {}
      pParamsDthBnfr.data.elstPartnNo     = amlDthBnfrPartnNo01     // 대상고객 BPID
      pParamsDthBnfr.data.mobslChnlCustId = amlDthBnfrChnlCustId01  // 대상고객채널ID
      pParamsDthBnfr.data.mnContrNm       = amlDthBnfrCustNm01      // 대상고객명
      pParamsDthBnfr.data.dwScCd          = '01'                    // 거주구분 국내 01로 보내고 처리계에서 CRM 값 우선으로 비교 처리 

      rsltUpdDthBnfr = await PSServiceManager.invokeSendAsync(pParamsDthBnfr)  //업데이트 수행

      if(rsltUpdDthBnfr && rsltUpdDthBnfr.isError){ // 사망수익자01 업데이트 결과 에러 처리
        rtnData.isError = true
        msgCntnt = '사망수익자01 기준정보 업데이트 실패'+'\n'
      }

    }

    //친권자01 업데이트 수행
    if(amlPrtrPesonPartnNo01 &&
      amlPrtrPesonPartnNo01 != amlDthBnfrPartnNo01 && 
      amlPrtrPesonPartnNo01 != contrPartnNo && 
      amlPrtrPesonPartnNo01 != insrdPartnNo ){ //친권자01 존재 수친 /계친 / 피친 상이일때 업데이트       

      let pParamsPrtr = {}
      pParamsPrtr = Object.assign({}, PSServiceManager.getParameter('txTSSPS13U4')) 
      pParamsPrtr.srnId = screenId // 대표 화면 명

      pParamsPrtr.data = {} 
      pParamsPrtr.data.elstPartnNo     = amlPrtrPesonPartnNo01   // 대상고객 BPID
      pParamsPrtr.data.mobslChnlCustId = prtrChnlCustId01  // 대상고객채널ID
      pParamsPrtr.data.mnContrNm       = prtrNm01          // 대상고객명
      pParamsPrtr.data.dwScCd          = '01'              // 거주구분 국내 01로 보내고 처리계에서 CRM 값 우선으로 비교 처리 

      rsltUpdPrtr = await PSServiceManager.invokeSendAsync(pParamsPrtr)  //업데이트 수행

      if(rsltUpdPrtr && rsltUpdPrtr.isError){ // 친권자01 업데이트 결과 결과 에러 처리
        rtnData.isError = true
        msgCntnt += '친권자01 기준정보 업데이트 실패'+'\n'
      }

    }
   
    return rtnData
  }


/******************************************************************************
 * Function명  : PSAmlUtil.fn_GetMdCustInfoForAml
 * 설명        : AML 대상 수행 고객 기준정보 조회
 *              BPID로 고객 기준정보 조회
 ******************************************************************************/
 PSAmlUtil.fn_GetMdCustInfoForAml = async function(context,custBpId,custNm){
  let pParams = {}
  let srnId = (context && context.$options && context.$options.screenId)? context.$options.screenId : 'MSPPS610M'

  //pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S6')) //조회결과 고객정보 평문
  pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S10'))//조회결과 고객정보 암호화 C339_F1MDI0386_S

  pParams.srnId = srnId 
  pParams.data = {}  
  pParams.data.BUSINESSPARTNER = {}
  pParams.data.BUSINESSPARTNER.ACTIVITY_CODE = 'R' // 조회:R / 변경:U
  pParams.data.BUSINESSPARTNER.PARTNER = custBpId   //BP ID
  pParams.data.BUSINESSPARTNER.GENERALDATA_S = {}
  pParams.data.BUSINESSPARTNER.GENERALDATA_S.BP_CONTROL = {}
  pParams.data.BUSINESSPARTNER.GENERALDATA_S.BP_CONTROL.CATEGORY = '1' // 개인(1)/법인(2)
  pParams.data.IS_HEADER = {}
  pParams.data.IS_HEADER.USERID = PSServiceManager.getUserInfo('userEno') // 처리자 사번
  pParams.data.IS_HEADER.ZA_BUSN_TRT_PATH_CD = '2220' // 업무처리경로코드
  pParams.data.IS_HEADER.ZA_TRT_SYSTM_PATH_CD = '22' // 처리시스템경로코드
  pParams.data.IS_HEADER.CHDAT = '' // 처리일자
  pParams.data.IS_HEADER.CHTIM = '' // 처리시간

  let rsltData = await PSServiceManager.invokeSendAsync(pParams) // 고객기준정보 조회 실행

  if(rsltData.isError){
   return rsltData 
  }

  let knbScCd     = rsltData.data.businesspartner.idnumbers_T[0].identificationtype   // 주민번호 유형: ZPER01: 주민등록번호, ZPER02: 외국인등록번호, ZPER03: 여권식별번호
  let custEngNm   = rsltData.data.businesspartner.generaldata_S.bp_PERSON.secondname  // 영문이름
  let natyCd      = rsltData.data.businesspartner.generaldata_S.bp_PERSON.nationality // 국가 코드
  let jobCd       = rsltData.data.businesspartner.jobdata_S.za_INSR_JOB_CD             // 기준정보 직업코드 "012212" 형태  
  let zzDwlrScCd  = rsltData.data.businesspartner.generaldata_S.extentions.zz_DW_SC_CD //기준정보 01:국내/02:국외
  

  let rtnData ={
      custBpId  : custBpId //대상고객 BPID
    , custNm    : custNm //고객명    
    , custEngNm : (custEngNm)? String(custEngNm).trim() : '' // 고객명 영문
    , natyCd    : (natyCd)? String(natyCd).trim() : '' //국적코드
    , knbScCd   : knbScCd  //대상고객 내국인 ZPER01, 외국인 구분S    
    , jobCd     : jobCd
    , zzDwlrScCd : (zzDwlrScCd)? String(zzDwlrScCd).trim() : ''
    , isError   : false
    }

  return rtnData
 }

 
 /******************************************************************************
 * Function명  : PSAmlUtil.fn_ProcAmlCust
 * 설명        : AML 대사 수행
 *              대상고객 기준정보 호출 국적과 외국인여부 조회결과 값으로
 *              AML 수행
               context : 호출 화면 this
               isSetEdd : EDD 포함 파라미터 셋팅
               amlCustRltnTypCd : AML 고객 구분 1: 계약자 , 2 : 대리인(친권자) , 3 : 사망수익자
               pMdCust : PSAmlUtil.fn_GetMdCustInfoForAml 호출 결과 값 (기준정보)
               scnAcpnPsbData : 접수처리 관련 정보
               amlSaveData : 서식입력정보(테이블 저장된 데이타)               
 ******************************************************************************/
               
 PSAmlUtil.fn_ProcAmlCust = async function(context,amlCustRltnTypCd,isSetEdd,pMdCustAml,scnAcpnPsbData,amlSaveData){

  // AML 파라미터 셋팅
  let pParams = {}
  let srnId = (context && context.$options && context.$options.screenId)? context.$options.screenId : 'MSPPS610M'
  let dwlrScCd = ''
  let jobCd = ''

  //pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18S6'))//AS-IS  C339_F10670028_S
  pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS18S11'))//AML 대응 C340_F10670020_S
  pParams.srnId = srnId

  pParams.data = {}
  // 고정값
  pParams.data.prcsPrfmcScCd  = '3' // 고정값 3:모두확인정보의옵션임
  pParams.data.amlCustTypCd   = '1' // 고정값 AML고객유형코드 1:개인/2:단체
  pParams.data.amlBusnDtlScCd = 'NB02' // 고정값 AML업무상세구분코드
  pParams.data.dlChnlCd       = '12' // 고정값 거래채널코드
  pParams.data.trtFofOrgNo    = PSServiceManager.getUserInfo('trtFofOrgNo') // 처리지점조직번호
  pParams.data.trtrEno        = PSServiceManager.getUserInfo('trtrEno') // 처리자사번
  pParams.data.ralOwnerYn     = 'Y' // 실제소유자여부 Y:실소유자/N:아닌경우
  pParams.data.custId         = pMdCustAml.custBpId // 파트너ID 대상고객 BPID

  if (pMdCustAml.knbScCd === 'ZPER01') { // 내국인
    pParams.data.custNm       = pMdCustAml.custNm //this.scnAcpnPsbData.mnContrNm // 고객명 - 내국인인경우 필요
    pParams.data.custEngNm    = '' // 영문 고객명 
    pParams.data.natyCd       = (pMdCustAml.natyCd)? pMdCustAml.natyCd : 'KR'  // 국적코드 (bp 정보 그대로 입력해야 함)    
  } else { // 외국인
    pParams.data.custNm       = pMdCustAml.custEngNm // 고객명 - 내국인인경우 필수
    pParams.data.custEngNm    = pMdCustAml.custEngNm // 고객명 - 외국인인경우 필수
    pParams.data.natyCd       = pMdCustAml.natyCd // 국적코드 (기준정보 조회한 natyCd 입력)
  } // end else if
  
  if(amlCustRltnTypCd == '1'){ // 거주지정보 셋팅 계약자 일때
    dwlrScCd = scnAcpnPsbData.contrDwScCd // 계약자 사전입력단계에서 입력한 거주지정보 값 셋팅 (TTSD00002테이블저장된값)
    jobCd    = scnAcpnPsbData.jobCd      // 계약자 채널 직업코드
  }else { // 이외...
    
    if(pMdCustAml.zzDwlrScCd){
      dwlrScCd = pMdCustAml.zzDwlrScCd // 거주지정보 기준정보에 값이 있다면 있는 값으로 셋팅(기고객의 기계약으로 값이 있을경우)
    }else{
      dwlrScCd = '01' // 없으면사망수익자01/ 친권자 01:국내로 기본 신규고객으로 필동 받을시 거주지 입력 항목이 없어 빈값
    }

    //jobCd = pMdCustAml.jobCd // 기준정보의 직업코드 
    
    if(amlCustRltnTypCd == '2'){ //친권자
      jobCd = scnAcpnPsbData.amlPrtrData01.jobCd //친권자 채널 직업코드
    } else if(amlCustRltnTypCd == '3'){ //수익자
      jobCd = scnAcpnPsbData.amlPrtrData01.jobCd //사망수익자 채널 직업코드
    }

  }

  let contEntRsnCd = '' , fdSrcScCd = '' , jobInfoScCd = '' , asptnAsetScCd= ''
  if(amlSaveData){ //저장된 AML 서식 입력 정보
    contEntRsnCd  = (amlSaveData.contEntRsnCd)?  String(amlSaveData.contEntRsnCd).trim()  : '' //서식에 기입한 거래목적 코드 매칭
    fdSrcScCd     = (amlSaveData.fdSrcScCd)?     String(amlSaveData.fdSrcScCd).trim()     :''  //서식에 기입한 자금원천 코드 매칭값
    jobInfoScCd   = (amlSaveData.jobScCd)?       String(amlSaveData.jobScCd).trim()       :''  //서식에 기입한 직업정보 코드 매칭
    asptnAsetScCd = (amlSaveData.asptnAsetScCd)? String(amlSaveData.asptnAsetScCd).trim() :''  //서식에 기입한 자산총액 코드 매칭값
  }

  pParams.data.dwlrScCd       = dwlrScCd  //거주자구분코드 01:국내/02:국외
  pParams.data.insrJobCd      = jobCd     //보험직업코드(기준정보 조회한 직업코드 "012212" 형태 businesspartner.jobdata_S.za_INSR_JOB_CD)  

  //----------------------------- EDD 추가 정보 셋팅 ------------------------------------------------------

  if(isSetEdd){
    pParams.data.eddAddmInfoInptYn     = 'Y' //EDD추가정보입력여부 'Y'    
    pParams.data.contEntRsnCd          = contEntRsnCd //계약가입사유코드 서식의 거래목적 항목
    pParams.data.fdOrgplScCd           = fdSrcScCd //자금출처구분코드 서식의 자금원천 항목
    //서식의 직업정보 1:직장인 / 2:개인사업자 / 3:무직/학생/파악불가  => 대칭 파라미터 못찾음
    //서식의 재무정보  연소득(만원) => 대칭 파라미터 없음
    //서식의 자산총액(10억미만 / 10~100억 / 100~1,000억 / 1,000억 초과)  => 대칭 파라미터 못찾음
  }

  //------------------------------ 계약정보 셋팅 ---------------------------------------------------------

  let refrNo = scnAcpnPsbData.vuchId // 참조번호 KYC 처리내역 확인/변경 -> AS-IS AML 영수증번호 보냄  , TO-BE AML 영수증번호+대상고객BPID 

  if(amlCustRltnTypCd == '1' && scnAcpnPsbData.amlContrPartnNo){ //계약자 AML BPID 존재시 TO-BE AML 대사 파라미터 구성
    refrNo += scnAcpnPsbData.amlContrPartnNo;
  }else if(amlCustRltnTypCd == '2'){ // 친권자 TO-BE AML 대사 파라미터 구성
    refrNo += scnAcpnPsbData.amlPrtrPesonPartnNo;
  }else if(amlCustRltnTypCd == '3'){
    refrNo += scnAcpnPsbData.amlDthBnfrPartnNo //사망수익자 TO-BE AML 대사 파라미터 구성
  }

  if(refrNo != scnAcpnPsbData.vuchId){ //참조번호가 영수증번호 와 같지않다면 TO-BE 일때 파라미터 amlCustRltnTypCd 셋팅 전달 , AS-IS 구성안함
    pParams.data.amlCustRltnTypCd = amlCustRltnTypCd //AML고객관계유형코드 계약자 1 , 대리인(친권자) 2 , 수익자(사망수익자) 3  
  }

  pParams.data.refrNo           = refrNo 
  pParams.data.prcd             = scnAcpnPsbData.prcd // 상품코드  
  pParams.data.trtAmt01         = {}
  pParams.data.trtAmt01.amount  = scnAcpnPsbData.smtotPrm // 보험료 처리금액
  pParams.data.trtAmt02         = {}
  pParams.data.trtAmt02.amount  = scnAcpnPsbData.smtotPrm // 보험료 처리금액
  let padCylCd                  = scnAcpnPsbData.payfrqCd // 납입기간유형코드 00 일시납, 01 연납, 12  월납, 04 분기납
  
  let convPadCylCd = ''
  if (padCylCd === '00') {
    convPadCylCd = '99' // 일시납
  } else if (padCylCd === '12') {
    convPadCylCd = '01' // 월납
  } else if (padCylCd === '06') {
    convPadCylCd = '02' // 2월납
  } else if (padCylCd === '04') {
    convPadCylCd = '03' // 3월납
  } else if (padCylCd === '02') {
    convPadCylCd = '06' // 6월납
  } else if (padCylCd === '01') {
    convPadCylCd = '12' // 년납
  } else {
    convPadCylCd = '01'
  } // end else if

  pParams.data.padCylCd = convPadCylCd

  /* 서식 업종코드 기능 미구현으로 주석 처리함
  if(isSetEdd){ // EDD일경우 추가정보 기준정보로 업데이트 테스트 필요
    await PSAmlUtil.fn_UpdMdCustInfoForAmlEdd(context, pMdCustAml.custBpId , jobCd ,amlSaveData) 
  }  
 */

  let rsltDataAml = await PSServiceManager.invokeSendAsync(pParams) // AML대사 서비스 실행

  if(rsltDataAml.isError){
    return rsltDataAml
  }

  //------------------------------ 결과 처리 ---------------------------------------------------------
  let rsltCd         = rsltDataAml.data.rsltCd
  let wlNvtnRsltCd   = rsltDataAml.data.wlNvtnRsltCd
  let pepYn          = rsltDataAml.data.pepYn
  let rskEvalGrd     = rsltDataAml.data.rskEvalGrd
  let eddSancObjScCd = (rsltDataAml.data.eddSancObjScCd)? rsltDataAml.data.eddSancObjScCd : ''  
  let kycRsltCd      = (rsltDataAml.data.kycRsltCd)? rsltDataAml.data.kycRsltCd : '' 
  let logCustScTyNm  = ''

  let rtnData = {}
  let isToBeBlocking = true

  if(amlCustRltnTypCd == '1'){
    logCustScTyNm ='계약자'    
  }else if(amlCustRltnTypCd == '2'){
    logCustScTyNm ='친권자01'
  }else if(amlCustRltnTypCd == '3'){
    logCustScTyNm ='사망수익자01'
  }

   //계약자 이고 aml대상BPID가 없을 경우(TOBE AML 서식이 없을때) AS-IS 판단 처리 
  if (amlCustRltnTypCd == '1' && !scnAcpnPsbData.amlContrPartnNo){
    isToBeBlocking = false
  }
  
  if(isToBeBlocking){ // TO-BO AML 블로킹 결과 처리 

    if(rsltCd === 'X'){
      rtnData.amlObjYn = 'X'      
      rtnData.msgCntnt = '['+logCustScTyNm+']'+ rsltDataAml.data.msgCntnt
    } else if (wlNvtnRsltCd=='1' && pepYn==='N'){
      rtnData.amlObjYn = 'Y'
      rtnData.msgCntnt = '['+logCustScTyNm+' AML] 금융거래 제한 대상자'  
    } else if (eddSancObjScCd=='20' && kycRsltCd=='EDD' && wlNvtnRsltCd=='2' && pepYn=='Y'){ 
      rtnData.amlObjYn = 'Y'
      rtnData.msgCntnt = '['+logCustScTyNm+' AML] PEP 등재자'
    } else if (eddSancObjScCd=='10' && kycRsltCd=='EDD' && wlNvtnRsltCd=='3' && pepYn=='N'){ 
      rtnData.amlObjYn = 'Y'
      rtnData.msgCntnt = '['+logCustScTyNm+' AML] 고액자산가'    
    } else if (eddSancObjScCd=='10' && kycRsltCd=='EDD' && wlNvtnRsltCd=='4' && pepYn=='N'){
      rtnData.amlObjYn = 'Y'
      rtnData.msgCntnt = '['+logCustScTyNm+' AML] EDD 대상 W/L 등재자'
    } else if (eddSancObjScCd=='20' && kycRsltCd=='EDD' && wlNvtnRsltCd=='4' && pepYn=='N'){
      rtnData.amlObjYn = 'Y'
      rtnData.msgCntnt = '['+logCustScTyNm+' AML] 조치를 요하는 고위험국가(미얀마)'
    } else if (eddSancObjScCd=='20' && kycRsltCd=='EDD' && wlNvtnRsltCd=='7' && pepYn=='N'){ 
      rtnData.amlObjYn = 'Y'
      rtnData.msgCntnt = '['+logCustScTyNm+' AML] 가상자산사업자'
    } else {
      rtnData.amlObjYn = 'N' //통과
      rtnData.msgCntnt = ''
    }

    //AML대사 서비스 호출시 거래목적,자금원천 필수값은 아님 
    //서식 EDD 추가 입력값(거래목적,자금원천) 체크 처리, 값 없을때 지점접수 유도 
    //만일대비 전자서명 앱 전달 AML 서식 미입력값 방어 요청
    if(rtnData.amlObjYn == 'N' && isSetEdd && (contEntRsnCd=='' || fdSrcScCd =='') ){
      rtnData.amlObjYn = 'X' 
      if(contEntRsnCd == '') rtnData.msgCntnt = '['+logCustScTyNm+' AML] EDD 추가 입력 거래목적 값 필요'
      if(fdSrcScCd == ''){
        rtnData.msgCntnt += (rtnData.msgCntnt)? '\n' : ''
      } 
      rtnData.msgCntnt += '['+logCustScTyNm+' AML] EDD 추가 입력 자금원천 값 필요'
    }

  } else { // AS-IS AML 블로킹 결과 처리

    if (rsltCd === 'X') { // 입력값 오류        
      rtnData.amlObjYn = 'X'
      rtnData.msgCntnt = rsltDataAml.data.msgCntnt
    } else if (wlNvtnRsltCd === '1' && pepYn === 'N') {
      rtnData.amlObjYn = 'Y'
      rtnData.msgCntnt = '[AML]금융거래 제한 대상자로 불가'
    } else if (wlNvtnRsltCd === '2' && pepYn === 'Y') {
      rtnData.amlObjYn = 'Y'
      rtnData.msgCntnt = '[AML]W/L PEP 등재자로 불가'
    } else if (wlNvtnRsltCd === '3' && pepYn === 'N') {
      rtnData.amlObjYn = 'Y'
      rtnData.msgCntnt = '[AML]EDD(W/L등록 고객자산가)으로 불가'
    } else if (wlNvtnRsltCd === '4' && pepYn === 'N') {
      rtnData.amlObjYn = 'N'
      rtnData.msgCntnt = '[AML]EDD(금융거래제,W/L등록,국적국가W/L대상,미등록비영리단체)불가'
    } else if (wlNvtnRsltCd === '5' && pepYn === 'N') {
      rtnData.amlObjYn = 'Y'
      rtnData.msgCntnt = '[AML]WHITE LIST대상으로 불가'
    } else if (wlNvtnRsltCd === '6' && pepYn === 'N') {
      rtnData.amlObjYn = 'N' //통과 [계약자 AML] 위험평가 면재 대상상품 가능
      rtnData.msgCntnt = '' 
    } else if (wlNvtnRsltCd === '9' && pepYn === 'N' && rskEvalGrd === 'H') {
      rtnData.amlObjYn = 'Y'
      rtnData.msgCntnt = '[AML]고위험대상으로 불가'
    } else {
      rtnData.amlObjYn = 'N' //통과
      rtnData.msgCntnt = ''
    }// end else if
  }  

  /*사용자 이벤트 로그 기록 처리*/
  try {
    console.log('PSAmlUtil.fn_ProcAmlCust evtLog !!') 
    let evtNmLogStr = ''
    let evtkeyLogStr = (scnAcpnPsbData && scnAcpnPsbData.vuchId)? scnAcpnPsbData.vuchId : ' '
    let evtAmlObjYn = (rtnData.amlObjYn)? rtnData.amlObjYn : '' //AML 대사 결과    
    
    //테이블 컬럼길이 영.숫자 150자 주의 순서주의
    //순서 변경 추가시 PSAmlUtil.fn_AmlLogView 순서도 같이 변경 추가 할것...
    evtNmLogStr  = amlCustRltnTypCd+'|'+pMdCustAml.custBpId+'|'+isSetEdd+'|'+dwlrScCd+'|'+jobCd
    evtNmLogStr += '|'+contEntRsnCd+'|'+fdSrcScCd +'|'+isToBeBlocking
    evtNmLogStr += '|'+rsltCd+'|'+wlNvtnRsltCd+'|'+rskEvalGrd+'|'+pepYn+'|'+eddSancObjScCd+'|'+kycRsltCd+'|'+evtAmlObjYn
    if(evtNmLogStr.length >= 150) evtNmLogStr = evtNmLogStr.substr(0,145) + "..."
    PSCommUtil.prcsEvtLog(this,'PSAmlUtil',evtNmLogStr,evtkeyLogStr)
    let logview = PSAmlUtil.fn_AmlLogView(evtNmLogStr)
    console.log(logview)
    
    if(rtnData.msgCntnt){ //AML통과 불가시 결과내용 기록
      setTimeout(function(){ //PK 방지
        evtNmLogStr = amlCustRltnTypCd+'|'+rtnData.msgCntnt
        PSCommUtil.prcsEvtLog(this,'PSAmlUtil',evtNmLogStr,evtkeyLogStr)
        console.log(evtNmLogStr)  
      }, 300);
    } 

  } catch (error) {
   console.log('PSAmlUtil.fn_ProcAmlCust evtLog error!!',error) 
  }
  
  rtnData.isError = false
  return rtnData
 }

 /******************************************************************************
 * Function명  : PSAmlUtil.fn_UpdMdCustInfoForAml
 * 설명        : AML 서식입력 EDD추가정보 기준정보에 업데이트 처리 
 *              EDD 일때만 업데이트
 *              custBpId : 대상고객 BPID
 *              insrJobCd : 보험직업코드
 *              amlSaveData : 입력된 서식데이터
 ******************************************************************************/
  
  PSAmlUtil.fn_UpdMdCustInfoForAmlEdd = async function(context,custBpId,insrJobCd,amlSaveData){
    
    if(!(amlSaveData && custBpId)) return

      
    //서식입력값 길이 및 포멧 정제 처리
    //PSDocFormUtil.js  getAmlSaveFieldMatch = function(amlCustContRltnTypCd,el) 참고
    let addEddData = Object.assign({}, amlSaveData)

    let mobslYincmVal = (addEddData.mobslYincmVal)? String(addEddData.mobslYincmVal).trim() : '' //연간 순 이익 원단위
    mobslYincmVal = (isNaN(mobslYincmVal))? 0 : Number(mobslYincmVal) * 10000 //서식에선 만원단위 입력받음
    
    let asptnAsetScCd   = (addEddData.asptnAsetScCd)? String(addEddData.asptnAsetScCd).trim() : '' //추정자산구분코드
    let jobScCd         = (addEddData.jobScCd)? String(addEddData.jobScCd).trim() : '' //직업구분코드	1'(직장인), '2'(개인사업자), '3'(무직)
    let mobslCustJobpNm = (addEddData.mobslCustJobpNm)? String(addEddData.mobslCustJobpNm).trim() : ''  //직장명 CHAR(40)
    mobslCustJobpNm     = PSCommUtil.fn_CutByteLength(mobslCustJobpNm,40)

    let mobslDeptNm     = (addEddData.mobslDeptNm)? String(addEddData.mobslDeptNm).trim()    : ''    //부서명 CHAR(60)
    mobslDeptNm         = PSCommUtil.fn_CutByteLength(mobslDeptNm,60)

    let mobslJobpsNm    = (addEddData.mobslJobpsNm)? String(addEddData.mobslJobpsNm).trim()  : ''   //직위명	CHAR(40)
    mobslJobpsNm        = PSCommUtil.fn_CutByteLength(mobslJobpsNm,40)

    let mobslEcmpYmd    = (addEddData.mobslEcmpYmd)? String(addEddData.mobslEcmpYmd).trim().replace(/[-,:. ]/g, '').substr(0,8) : ''  //입사일자 TIMESTAMP(8)     
    let mobslBsnno      = (addEddData.mobslBsnno)? String(addEddData.mobslBsnno).trim().replace(/[-,:. ]/g, '').substr(0,10)    : ''      //사업자등록번호 CHAR(10)
    let mobslEstbYmd    = (addEddData.mobslEstbYmd)? String(addEddData.mobslEstbYmd).trim().replace(/[-,:. ]/g, '').substr(0,8) : ''  //조직설립일TIMESTAMP(8)    

    let pParams = {}
    let srnId = (context && context.$options && context.$options.screenId)? context.$options.screenId : 'MSPPS610M'
    
    pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S10'))//기준정보 C339_F1MDI0386_S 서비스 
  
    pParams.srnId = srnId 
    pParams.data = {}  
    pParams.data.BUSINESSPARTNER = {}
    pParams.data.BUSINESSPARTNER.ACTIVITY_CODE = 'U' // 조회:R / 변경:U
    pParams.data.BUSINESSPARTNER.PARTNER = custBpId   //BP ID
    pParams.data.BUSINESSPARTNER.GENERALDATA_S = {}
    pParams.data.BUSINESSPARTNER.GENERALDATA_S.BP_CONTROL = {}
    pParams.data.BUSINESSPARTNER.GENERALDATA_S.BP_CONTROL.CATEGORY = '1' // 개인(1)/법인(2)
    pParams.data.IS_HEADER = {}
    pParams.data.IS_HEADER.USERID = PSServiceManager.getUserInfo('userEno') // 처리자 사번
    pParams.data.IS_HEADER.ZA_BUSN_TRT_PATH_CD = '2220' // 업무처리경로코드
    pParams.data.IS_HEADER.ZA_TRT_SYSTM_PATH_CD = '22' // 처리시스템경로코드
    pParams.data.IS_HEADER.CHDAT = '' // 처리일자
    pParams.data.IS_HEADER.CHTIM = '' // 처리시간
  
    pParams.data.FSDATA_S ={} //금융 특화정보 구조
    pParams.data.FSDATA_S.NET_INCOME = mobslYincmVal // 연간 순 이익 원단위
    pParams.data.FSDATA_S.INCOME_CUR = 'KRW' //통화 원화
    pParams.data.FSDATA_S.ZZ_ASPTN_ASET_SC_CD = asptnAsetScCd //추정자산구분코드	'01'(10억원미만), '02'(10억원~100억원), '03'(100억원~1000억원), '04'(1000억원초과)

    pParams.data.JOBDATA_S = {} //직업정보 구조
    pParams.data.JOBDATA_S.ZA_JOB_SC_CD	  = jobScCd //서식에서 입력받은 직업구분코드	1'(직장인), '2'(개인사업자), '3'(무직)
    pParams.data.JOBDATA_S.ZA_INSR_JOB_CD	= (insrJobCd)? String(insrJobCd).trim() : '' //보험직업코드 AML대사 할때 직업코드
        
    pParams.data.JOBDATA_S.ZA_JOBP_NM  = mobslCustJobpNm  //개인-직장명/업체명
    pParams.data.JOBDATA_S.ZA_DEPT_NM  = mobslDeptNm      //개인-부서명
    pParams.data.JOBDATA_S.ZA_JOBPS_NM = mobslJobpsNm     //개인-직장인 직위명
    pParams.data.JOBDATA_S.ZA_ECMP_YMD = mobslEcmpYmd     //개인-직장인 입사일자
    pParams.data.JOBDATA_S.ZA_BSNNO    = mobslBsnno       //개인-개인사업자 사업자등록번호
    pParams.data.JOBDATA_S.FOUND_DAT   = mobslEstbYmd     //개인-개인사업자 설립일자    
    //pParams.data.JOBDATA_S.IND_SECTOR  = ''//개인-개인사업자 업종 코드(표준산업분류코드) 서식에서 코드 선택 기능 미구현 
    
    let rsltData = await PSServiceManager.invokeSendAsync(pParams) // 고객기준정보로 업데이트 실행
    
    console.log(rsltData)

    return rsltData
   }


 /******************************************************************************
 * Function명  : PSAmlUtil.fn_AmlLogView
 * 설명        : 사용자 이벤트 로그 View 처리
 *               크롬 콘솔에서 함수 등록 후 테이블 로그값 String 인자로 실행               
 ******************************************************************************/
 PSAmlUtil.fn_AmlLogView = function(log){
  let evtLogColList = ['amlCustRltnTypCd(고객구분코드)','custBpId(고객BPID)','isSetEdd(TOBE AML EDD 셋팅여부)','dwlrScCd(거주지정보)','jobCd(직업코드)'
                      ,'contEntRsnCd(거래목적코드)','fdSrcScCd(자금원천코드)','isToBeBlocking(TOBE AML 결과체크대상여부)'
                      ,'rsltCd','wlNvtnRsltCd','rskEvalGrd','pepYn','eddSancObjScCd','kycRsltCd','amlObjYn(블로킹여부)']
  let logSplit = (log)? log.split('|') : []
  let rsltList = []

  for(let i = 0 ; i < evtLogColList.length ; ++i){
    let o = {}
    try {
      o[evtLogColList[i]] = (logSplit[i])?logSplit[i] : null
    } catch (error) {
      o[evtLogColList[i]] = null
    }
    
    rsltList.push(o)
  }//for i
   
  return rsltList
 }  
 

 /******************************************************************************
 * Function명  : PSAmlUtil.fn_SetSaveAmlDocWrt
 * 설명        : 전자서명 완료 AML 서식 정보 저장 처리
               context : 호출 화면 this
               eltrnSaveData : 전자서명 진행 데이터 
               reSignerData : 전자서명앱에서 전달 받은 데이터 Object            
 ******************************************************************************/

PSAmlUtil.fn_SetSaveAmlDocWrt = async function(context , eltrnSaveData , gafDataAmlKycRsltCd , amlWrtDataList){

  let vuchId = eltrnSaveData.vuchId // 영수증 번호  
  let pParams = {}
  let srnId = (context && context.$options && context.$options.screenId)? context.$options.screenId : 'MSPPS610M'
  let saveAmlWrtDataList =[]

  amlWrtDataList = (amlWrtDataList)? amlWrtDataList : [] 
  let len = amlWrtDataList.length

  console.log("전자서명 완료 AML 서식 정보 저장 처리")
  console.log(gafDataAmlKycRsltCd)
  console.log(amlWrtDataList)

  if(! gafDataAmlKycRsltCd || len == 0 ) return
  
  //서식에 정보가 있으면 저장처리
  for(let i = len -1 ; i >= 0 ; --i){
    if(amlWrtDataList[i].amlCustContRltnTypCd == '1'){ //계약자 
      saveAmlWrtDataList.push(amlWrtDataList[i])    
    }else if(amlWrtDataList[i].amlCustContRltnTypCd == '3'){ //사망수익자01
      saveAmlWrtDataList.push(amlWrtDataList[i])
    }else if(amlWrtDataList[i].amlCustContRltnTypCd == '2'){ //친권자01
      saveAmlWrtDataList.push(amlWrtDataList[i])
    } 
    
  }//for i

  console.log("전자서명 완료 AML 서식 정보 저장 처리")  
  console.log(saveAmlWrtDataList)
  if(saveAmlWrtDataList.len == 0 ) return
  
  pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99I4'))
  pParams.srnId = srnId
  pParams.data = {}
  pParams.data.vuchId = vuchId // 영수증 번호
  pParams.data.listAmlDocWrtVO = saveAmlWrtDataList

  let rsltData = await PSServiceManager.invokeSendAsync(pParams)  
 
  console.log(rsltData)
}
 /******************************************************************************
 * Function명  : PSAmlUtil.fn_GetSaveAmlDocWrt
 * 설명        : 영수증 번호로 저장된 AML 서식 정보 조회
               context : 호출 화면 this
               vuchId  : 영수증 번호
 ******************************************************************************/

PSAmlUtil.fn_GetSaveAmlDocWrt = async function(context,vuchId){
  let pParams = {}
  let srnId = (context && context.$options && context.$options.screenId)? context.$options.screenId : 'MSPPS610M'
  pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99S13'))
  pParams.srnId = srnId
  pParams.data = {}
  pParams.data.vuchId = vuchId // 영수증 번호

  let rslt = await PSServiceManager.invokeSendAsync(pParams) // 저장된 AML 서식 입력값 조회 실행
  
  if(rslt.isError == true) return []

  let amlWrtDataList = (rslt.data && rslt.data.listAmlDocWrtVO)? rslt.data.listAmlDocWrtVO : []

  return amlWrtDataList
}
  

 export default PSAmlUtil